define('modules/components/helpers/about',['require','modules/core/base-view','tmpl!modules/components/helpers/about'],function(require) {

  var BaseView = require('modules/core/base-view');
  var template = require('tmpl!modules/components/helpers/about');

  return BaseView.extend({
    template: template
  });

});

