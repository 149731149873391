
define('tmpl!modules/components/comments/collection-view', ['lodash'], function(_) {return function(obj) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj || {}) {
__p += '<div class="comments">\n\n  ';
 var leaveComments = loggedIn; ;
__p += '\n\n  ';
 if (leaveComments) { ;
__p += '\n\n    <form class="comment-form">\n      <fieldset>\n        <legend>Leave a Comment</legend>\n\n        <div class="control-group">\n          <textarea id="comment" name="comment" rows="3" class="input-xlarge"></textarea>\n        </div>\n\n        <div class="control-group">\n          <div class="controls">\n            <button id="submit" name="submit" class="btn btn-primary">Post</button>\n          </div>\n        </div>\n\n      </fieldset>\n    </form>\n\n  ';
 } else { ;
__p += '\n    You must be logged in to leave a comment.\n  ';
 } ;
__p += '\n\n  <div class="posted-comments">\n    ';
 if (comments.length) { ;
__p += '\n\n      ';
 _.each(comments, function(comment) { ;
__p += '\n\n        <div class="comment">\n          <div class="body">\n            ' +
((__t = ( comment.comment )) == null ? '' : __t) +
'\n          </div>\n\n          <div class="author">\n            Posted by: <a href="http://twitter.com/' +
((__t = ( comment.twitter_handle )) == null ? '' : __t) +
'">\n              @' +
((__t = ( comment.twitter_handle )) == null ? '' : __t) +
'</a>,\n              ' +
((__t = ( comment.timestamp )) == null ? '' : __t) +
'\n          </div>\n\n        </div>\n      ';
 }); ;
__p += '\n\n    ';
 } else { ;
__p += '\n      There are no comments yet.\n    ';
 } ;
__p += '\n  </div>\n\n</div>\n';

}
return __p
};});

