
define('tmpl!modules/layouts/single-col', ['lodash'], function(_) {return function(obj) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj || {}) {
__p += '<header>\n  <div class="block">\n    <a class="logo" href="/"><img src="/src/assets/mobilevis-logo.png"></a>\n    <nav>\n      <input type="checkbox" id="button">\n      <label for="button" onclick=""></label>\n      <ul class="menu" id="menu" role="custom-dropdown">\n        <li><a href="/about">about</a></li>\n        <li><a href="http://patterns.mobilev.is">patterns</a></li>\n      </ul>\n    </nav>\n\n  </div>\n</header>\n\n<section>\n  <div class="block">\n    <div id="flash"></div>\n    <div id="breadcrumbs"></div>\n    <div id="about"></div>\n    <div class="content"></div>\n  </div>\n</section>\n';

}
return __p
};});

