define('modules/components/submissions/collection',['require','backbone','modules/components/submissions/model','modules/services/api'],function(require) {
  var Backbone = require('backbone');
  var Submission = require('modules/components/submissions/model');

  var API = require("modules/services/api");

  return Backbone.Collection.extend({
    model: Submission,
    url : function() {
      return API.submissions.all;
    }
  });

});

