
define('tmpl!modules/components/submissions/show-view', ['lodash'], function(_) {return function(obj) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj || {}) {
__p += '<div class="submission row">\n  <div class="metadata">\n\n    <!-- uses swipe to see multiple images -->\n    <div class="col-md-8">\n\n      <div class="image-container">\n        ';
 if (submission.images.length > 1) { ;
__p += '\n          <div class="image-control image-before"></div>\n          <div class="image-control image-after"></div>\n        ';
 } ;
__p += '\n\n        <div class="image" class="images">\n          <div class="swiper-wrapper">\n            ';
 _.each(submission.images, function(image) { ;
__p += '\n              <div class="swiper-slide">\n                <img src="/data/images/' +
((__t = ( image.url )) == null ? '' : __t) +
'" />\n              </div>\n            ';
 }); ;
__p += '\n          </div>\n        </div>\n      </div>\n    </div>\n\n    <div class="col-md-4">\n      <h3 class="title">' +
((__t = ( submission.name )) == null ? '' : __t) +
'</h3>\n      <div class="creator">by <a data-creator="' +
((__t = ( submission.creator )) == null ? '' : __t) +
'">' +
((__t = ( submission.creator )) == null ? '' : __t) +
'</a></div>\n      <div class="submitted_by">\n        Submitted by:\n        <a data-user="' +
((__t = ( submission.twitter_handle )) == null ? '' : __t) +
'">\n          @' +
((__t = ( submission.twitter_handle )) == null ? '' : __t) +
'</a>,\n          ' +
((__t = ( submission.timestamp )) == null ? '' : __t) +
'\n      </div>\n      <div class="description">\n        <p>\n          ' +
((__t = ( submission.description )) == null ? '' : __t) +
'\n        </p>\n      </div>\n\n       <div class="controls">\n        <a class="see-live" href="' +
((__t = ( submission.original_url )) == null ? '' : __t) +
'">See live</a>\n        ';
 if (user && (user.isAdmin || submission.twitter_handle === user.username)) { ;
__p += '\n          | <a href="#" id="edit">Edit</a>\n        ';
 } ;
__p += '\n\n        ';
 if (user && user.isAdmin) { ;
__p += '\n          | <a href="#" id="delete">Delete</a>\n        ';
 } ;
__p += '\n      </div>\n\n      <h3 class="tags-title">Tags</h3>\n      <div class="tags">\n        ';
 if (submission.tags.length) { ;
__p += '\n          ';
 submission.tags.forEach(function(tag) { ;
__p += '\n            <span class="tag" data-tag="' +
((__t = ( tag.tag )) == null ? '' : __t) +
'" data-tag-id="' +
((__t = ( tag.id )) == null ? '' : __t) +
'">' +
((__t = ( tag.tag )) == null ? '' : __t) +
'</span>\n          ';
 }); ;
__p += '\n        ';
 } ;
__p += '\n      </div>\n      <!-- comments and any extra data goes here -->\n      <div class="extra"></div>\n    </div>\n\n  </div>\n\n</div>\n';

}
return __p
};});

