
define('tmpl!modules/components/submissions/collection-view', ['lodash'], function(_) {return function(obj) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj || {}) {
__p += '<ul class="submissions">\n  ';
 _.each(submissions, function(submission) { ;
__p += '\n    <li class="submission" data-submission-id="' +
((__t = ( submission.id )) == null ? '' : __t) +
'">\n      <div class="submission-content">\n        <div class="image">\n          <div style="background-image: url(/data/images/' +
((__t = ( submission.main_image.url )) == null ? '' : __t) +
');" />\n        </div>\n\n        <div class="submission-info">\n          <h3 class="title">' +
((__t = ( submission.name )) == null ? '' : __t) +
'</h3>\n          <div class="creator">by <a data-creator="' +
((__t = ( submission.creator )) == null ? '' : __t) +
'">' +
((__t = ( submission.creator )) == null ? '' : __t) +
'</a></div>\n          <div class="submitted_by">\n            Submitted by:\n            <a data-user="' +
((__t = ( submission.twitter_handle )) == null ? '' : __t) +
'">\n              @' +
((__t = ( submission.twitter_handle )) == null ? '' : __t) +
'\n            </a>, ' +
((__t = ( submission.timestamp )) == null ? '' : __t) +
'.\n            <br/>\n            ';
 if (submission.images.length > 1) { ;
__p += '\n              There are <b>' +
((__t = ( submission.images.length )) == null ? '' : __t) +
'</b> screenshots.\n            ';
 } else { ;
__p += '\n              There is <b>1</b> screenshot.\n            ';
 } ;
__p += '\n\n          </div>\n          <div class="tags">\n            ';
 if (submission.tags.length) { ;
__p += '\n              ';
 submission.tags.forEach(function(tag) { ;
__p += '\n                <span class="tag" data-tag="' +
((__t = ( tag.tag )) == null ? '' : __t) +
'" data-tag-id="' +
((__t = ( tag.id )) == null ? '' : __t) +
'">' +
((__t = ( tag.tag )) == null ? '' : __t) +
'</span>\n              ';
 }); ;
__p += '\n            ';
 } ;
__p += '\n          </div>\n        </div>\n      </div>\n    </li>\n  ';
 }); ;
__p += '\n</ul>\n';

}
return __p
};});

