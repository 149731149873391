
define('tmpl!modules/components/helpers/welcome-view', ['lodash'], function(_) {return function(obj) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj || {}) {
__p += '<section id="welcome">\n\n  <div class="control close">\n    X\n  </div>\n\n  <p>\n    Welcome to <span class="mobilevis">MobileVis</span>!\n    Here we gather examples of data\n    visualization usage on mobile devices and offer some observed\n    <a href="http://patterns.mobilev.is">best practices</a>\n    for building your mobile data visualization interfaces.\n  </p>\n\n  <p>\n    <h3>Learn More!</h3>\n      Learn more about <span class="mobilevis">MobileVis</span> <a href="/about">here</a>\n      or submit any issues on <a href="http://github.com/bocoup/mobilevis">github</a>.\n  </p>\n\n</section>\n';

}
return __p
};});

