
define('tmpl!modules/components/helpers/breadcrumbs', ['lodash'], function(_) {return function(obj) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj || {}) {
__p += '<h3 class="breadcrumbs">\n  ';
 if (typeof tag !== "undefined") { ;
__p += '\n    Examples tagged with "' +
((__t = ( tag.tag )) == null ? '' : __t) +
'":\n  ';
 } ;
__p += '\n\n  ';
 if (typeof twitter_handle !== "undefined") { ;
__p += '\n    Examples submitted by <a href="http://twitter.com/' +
((__t = ( twitter_handle )) == null ? '' : __t) +
'">@' +
((__t = ( twitter_handle )) == null ? '' : __t) +
'</a>:\n  ';
 } ;
__p += '\n\n  ';
 if (typeof creator !== "undefined") { ;
__p += '\n    Examples created by ' +
((__t = ( creator )) == null ? '' : __t) +
':\n  ';
 } ;
__p += '\n</h3>\n';

}
return __p
};});

